body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1 {
  color: black;
  padding:15px;
}

h2{


color: black;

}

h2{


color: black;

}

h3{

text-align: center;
color: black;

}

h4{


color: black;

}

p {
  color: black;
  font-weight: 200;


}

p.main {
  color: white;
  font-weight: 300;
  text-align: left;
}

h2.know {
  color: white;
  font-weight: 300;
  text-align: left;
}
li {
  color: black;
  font-weight: 300;
  padding:5px;
  align:justify;

}

li.style {
  color: black;
  font-weight: 300;
  padding:5px;
  align:justify;
  list-style-type: none;
}

/* unvisited link */
a:link {
  color:black;
}

/* visited link */
a:visited {
  color: black;
}

/* mouse over link */
a:hover {
  color: orange;
}

/* selected link */
a:active {
  color: blue;
}
