.jumbotron {
  margin-bottom: 4em;
   background-image: url("../images/bg1.jpg");
  background-size: cover;
}
.person-wrapper {
  margin-bottom: 2em;
}

.profile-pic {
  width: 50%;
}

.btn-primary {
  background-image: none;
  border: none;
  border-radius: 0;
  background-color: #2892D7;
}

h3 {
  margin-bottom: 1em;
}

.main{

	color:white;
}

.col {
padding: 1rem;
background-color: #33b5e5;
border: 2px solid #fff;
color: #fff;
text-align: center;
}