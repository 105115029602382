nav.navbar, .navbar-default {
  background-color: white !important;
  color:black;
}

.navbar-default {
  background-image: none;
  border: none;
  border-radius: 0;
}

.navbar-default .navbar-nav > li > a {
  color: black;
}

.navbar-default .navbar-brand {
  color: black;
}

.navbar-default .navbar-toggle {
  border-color: black;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: black;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

.navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
  color: black;
  background-color: transparent;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: black;
  background-color: transparent;
}