.header-image {
  width: 100%;
  margin-top: -1em;
  margin-bottom: 1em;
  height:150px;

}

.about-profile-pic {
  float: left;
  margin: 0 1em 1em 0;
}