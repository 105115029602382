.social-container {
  
  padding: 10px 10px;
}

a.social {
  margin: 0 2rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color:black;
  

}

a.google {
  color:black;
  

}
a.facebook {
  color:black;
   
}

a.twitter {
  color: black;
  
}

a.instagram {
  color: black;
   
}

.fa-2x {
    font-size: 1em;

}

